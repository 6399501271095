/**
 * .c-expo
 */

$component: 'expo';
#{'.' + $global-components-namespace + $component} {
    // @extend %u-ov-hidden;
    @extend %u-pos-relative;
    @extend %u-grid;
    a:after {
        @extend %u-pos-absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
    }
    &__meta {
        font-size: 15px;
        color: $color-mist;
        line-height: 24px;
        // text-transform: lowercase;
    }
    &__title {
        // margin-top: baselines(0.5);
        font-size: 1.15rem;
        font-weight: bold;
        line-height: 1.65;
    }
    @media #{$move-poster-right} {
        // grid-template-rows: #{baselines(16)} auto;
    }
    grid-gap: baselines(4);
    &__thumbnail {
        @extend %u-pos-relative;
        @extend %u-block;
        aspect-ratio: 4 / 3;
        // background-color: $color-charcoal-base-darker;
        // border-radius: 0% 25% 10% 60%;
        // margin-top: baselines(3);
        &-image {
            @extend %u-pos-absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            // padding: 3% 3% 1%;
            border-radius: 0% 25% 15%;
            // object-position: top left;
            // transition: padding .1s ease, border-radius .1s ease;
        }
        &-face {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            width: 15%;
            object-fit: cover;
            aspect-ratio: 1 / 1;
            border-radius: 100%;
            box-shadow: 0 1px 3px rgba(0, 0, 0, .4), 0 2px 5px rgba(0, 0, 0, .2);
        }
    }
    &:hover &__thumbnail,
    &:focus &__thumbnail {
        // @extend %u-bgcolor-mango;
        // padding: 0 0 10px 0;
        // border-top-right-radius: 20%;
        // border-bottom-right-radius: 30%;
        // border-bottom-left-radius: 20%;
        // background-color: transparent;
    }
}