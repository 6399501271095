@font-face {
    font-family: 'Hope Sans';
    font-weight: 400; // normal
    src: url('/src/font/hope-sans/regular/font.woff2') format('woff2'), url('/src/font/hope-sans/regular/font.woff') format('woff');
}

@font-face {
    font-family: 'Hope Sans';
    font-weight: 400; // normal
    font-style: italic;
    src: url('/src/font/hope-sans/italic/font.woff2') format('woff2'), url('/src/font/hope-sans/italic/font.woff') format('woff');
}

@font-face {
    font-family: 'Hope Sans';
    font-weight: 600; // semibold
    src: url('/src/font/hope-sans/semibold/font.woff2') format('woff2'), url('/src/font/hope-sans/semibold/font.woff') format('woff');
}

@font-face {
    font-family: 'Hope Sans';
    font-weight: 700; // bold
    src: url('/src/font/hope-sans/bold/font.woff2') format('woff2'), url('/src/font/hope-sans/bold/font.woff') format('woff');
}

body {
    @extend %u-color-paste;
    @extend %u-family-hope-sans;
    font-size: 17px;
    font-family: 'Hope Sans', Europa, 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: 300;
}