/**
 * .c-back
 */

$component: 'back';

#{'.' + $global-components-namespace + $component} {
  @extend %u-bgcolor-charcoal-base-darker;
  --ReturnHeight: #{baselines(11)};
  height: var(--ReturnHeight);

  &__link {
    @extend %u-pos-fixed;
    @extend %u-top-0;
    @extend %u-z-20;
    @extend %u-block;
    @extend %u-mr-auto;
    line-height: var(--ReturnHeight);
  }
}