body {
  --color-white: #fff;
  --color-gunge: #50e3c2;
  --color-mango: #f7c257;
}

.c-expo__thumbnail-image, .c-expo a:after, .u-pos-absolute, .o-dictate, .o-abscentre-x, .o-abscentre-y, .o-abscentre, .c-icon-link__icon:before, .c-poster__network-icon:before, .o-absfill, .o-aspect-ratio > * {
  position: absolute;
}

.c-progress__screen, .c-icon-link__icon, .c-icon-link, .c-poster__network-icon, .c-poster__network-link, .c-header__title, .c-header, .c-expo__thumbnail, .c-expo, .o-hide, .u-pos-relative, .o-aspect-ratio {
  position: relative;
}

.c-back__link, .u-pos-fixed {
  position: fixed;
}

.c-progress, .u-pos-sticky {
  position: sticky;
}

.c-back__link, .u-top-0, .o-absfill, .o-aspect-ratio > * {
  top: 0;
}

.u-top-50pc, .o-abscentre-y, .o-abscentre, .c-icon-link__icon:before, .c-poster__network-icon:before {
  top: "50%";
}

.u-top-100pc {
  top: "100%";
}

.u-left-0, .o-absfill, .o-aspect-ratio > * {
  left: 0;
}

.u-left-50pc, .o-abscentre-x, .o-abscentre, .c-icon-link__icon:before, .c-poster__network-icon:before {
  left: "50%";
}

.u-left-100pc {
  left: "100%";
}

.o-hide {
  z-index: -1;
}

.c-back__link {
  z-index: 20;
}

.c-header, .o-ellipsis, .o-hide, .u-ov-hidden, .o-dictate {
  overflow: hidden;
}

.u-ovx-hidden, .u-ovy-hidden {
  overflow-x: hidden;
}

.o-hide {
  clip: "rect(0, 0, 0, 0)";
}

.o-dictate {
  clip: "rect(1px, 1px, 1px, 1px)";
}

.u-color-white {
  color: #fff;
}

.token.function, .token.class-name, .token.punctuation, .token.property, body, .u-color-paste {
  color: #fafcfc;
}

.c-header__tags, :not(pre) > code[class*="language-"], .u-color-gunge {
  color: #50e3c2;
}

.c-icon-link:hover, .c-poster__network-link:hover, .c-external-link, .s-article p a, .c-article figcaption p a, .u-color-mango {
  color: #f7c257;
}

.u-color-charcoal-base-lighter {
  color: #262c39;
}

.u-color-charcoal-base {
  color: #212733;
}

.u-color-charcoal-base-darker {
  color: #181d26;
}

.u-color-purple-base {
  color: #602087;
}

.c-progress__screen, .c-icon-link__icon, .c-icon-link, .c-poster__network-icon, .c-poster__network-link, .c-container-units-demo__column, .u-ai-center {
  align-items: center;
}

.u-ai-flex-start {
  align-items: flex-start;
}

.c-header--container-units .c-header__title, .u-as-center {
  align-self: center;
}

.u-as-flex-start {
  align-self: flex-start;
}

.c-icon-link__icon, .c-poster__network-icon, .c-container-units-demo__column, .u-jc-center {
  justify-content: center;
}

.c-progress__screen, .c-container-units-demo, .u-jc-space-between {
  justify-content: space-between;
}

.c-header--container-units .c-header__title, .u-js-center {
  justify-self: center;
}

.c-header__title, .u-fd-column {
  flex-direction: column;
}

.u-fd-column-reverse {
  flex-direction: column-reverse;
}

body.t-article, .u-fd-row-reverse {
  flex-direction: row-reverse;
}

body.t-article, .u-fw-wrap {
  flex-wrap: wrap;
}

.u-auto {
  display: auto;
}

.c-progress__screen, .c-icon-link__icon, .c-icon-link, .c-poster__network-icon, .c-poster__network-link, .c-poster__networks, .c-header__title, .c-container-units-demo__span, .c-container-units-demo__column, .c-container-units-demo, body.t-article, .u-flex {
  display: flex;
}

.u-inline-flex {
  display: inline-flex;
}

.c-header--container-units, .c-expo, .c-content-feed, .u-grid {
  display: grid;
}

.c-menu a, .c-expo__thumbnail, .c-back__link, .u-block, .o-aspect-ratio:before {
  display: block;
}

.u-none {
  display: none;
}

.u-height-100pc, .o-absfill, .o-aspect-ratio > * {
  height: 100%;
}

.o-dictate {
  height: 1px;
}

.c-back__link, .u-mr-auto {
  margin-right: auto;
}

.c-progress__screen, .u-mh-auto, .o-container {
  margin-left: auto;
  margin-right: auto;
}

.c-progress__screen, .u-mv-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.u-m-0, .u-m-children-0 > * {
  margin: 0;
}

.u-mt-siblings-s > * + * {
  margin-top: 1rem;
}

.u-mt-siblings-m > * + * {
  margin-top: 3rem;
}

.u-mt-siblings-l > * + * {
  margin-top: 6rem;
}

.u-mt-children-xl > * {
  margin-top: 7.5rem;
}

.u-gap-1 {
  gap: 1rem;
}

body, .u-family-hope-sans {
  font-family: "\"Hope Sans\", Europa, \"Helvetica Neue\", Helvetica, sans-serif";
}

.o-type-h0, .o-type-h1, .s-article h1, .c-article figcaption h1, .o-type-h2, .s-article h2, .c-article figcaption h2, .u-weight-bold {
  font-weight: bold;
}

.c-icon-link__text, .c-poster__network-text, .o-ellipsis {
  white-space: nowrap;
}

.c-progress__screen:before, .c-progress__screen:after, .o-aspect-ratio:before {
  content: "";
}

.o-hide, .u-events-none {
  pointer-events: none;
}

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, main, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

html, body {
  min-height: 100%;
}

html {
  scroll-padding-top: 6rem;
  overflow-x: hidden;
  overflow-y: auto;
}

:root {
  line-height: 1;
}

article, aside, details, figcaption, figure, main, footer, header, hgroup, menu, nav, section {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

a {
  vertical-align: baseline;
  color: inherit;
  -webkit-tap-highlight-color: #0000;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 100%;
  text-decoration: none;
}

a:hover, a:focus, a:active {
  -webkit-tap-highlight-color: #0000;
}

ins {
  color: #000;
  background-color: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  cursor: help;
  border-bottom: 1px dotted;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
}

button {
  text-align: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

label, button, select {
  cursor: pointer;
}

input, select {
  vertical-align: middle;
}

input, select, textarea, button {
  font: inherit;
  color: inherit;
}

input::-moz-focus-inner, button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

code {
  font: inherit;
}

*, :before, :after, * * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: #fff0 !important;
  -webkit-focus-ring-color: #fff0 !important;
}

html, body {
  background-color: #212733;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  --Baseline: 6px;
  --Spacing: var(--Baseline);
  --ArticleWidth: var(--ContainerWidth);
  --ArticleHeaderHeight: 60vh;
}

@media screen and (min-width: 1100px) {
  body {
    --ArticleWidth: calc(7 * (var(--ColumnUnit)  + var(--GutterUnit))  - var(--GutterUnit));
  }
}

body {
  --CUDemoGridWidth: 1062;
  --CUDemoGridColumnWidth: 68;
  --CUDemoGridColumns: 12;
  --CUDemoGutters: calc(var(--CUDemoGridColumns)  - 1);
  --CUDemoColumnProportion: calc(var(--CUDemoGridColumnWidth) / var(--CUDemoGridWidth));
  --CUDemoGutterProportion: calc((1 - (var(--CUDemoGridColumns) * var(--CUDemoColumnProportion))) / var(--CUDemoGutters));
  --CUDemoColumnUnit: calc(var(--CUDemoColumnProportion) * var(--CUDemoWidth));
  --CUDemoGutterUnit: calc(var(--CUDemoGutterProportion) * var(--CUDemoWidth));
  --CUDemoGridColumnspanUnit: calc(var(--CUDemoGridColumnspans) * (var(--CUDemoColumnUnit)  + var(--CUDemoGutterUnit))  - var(--CUDemoGutterUnit));
  --CUDemoWidth: 92vw;
  --CUDemoDuration: 1.5s;
}

@media screen and (min-width: 1100px) {
  body {
    --CUDemoWidth: 80vw;
  }
}

@media screen and (min-width: 1440px) {
  body {
    --CUDemoWidth: 1150px;
  }
}

body {
  --GridWidth: 1332;
  --GridColumnWidth: 72;
  --GridColumns: 15;
  --GridGutters: calc(var(--GridColumns)  - 1);
  --ColumnProportion: calc(var(--GridColumnWidth) / var(--GridWidth));
  --GutterProportion: calc((1 - (var(--GridColumns) * var(--ColumnProportion))) / var(--GridGutters));
  --ColumnUnit: calc(var(--ColumnProportion) * var(--ContainerWidth));
  --GutterUnit: calc(var(--GutterProportion) * var(--ContainerWidth));
  --ColumnAndGutterUnit: calc(var(--ColumnUnit)  + var(--GutterUnit));
  --ContainerWidth: 86vw;
}

@media screen and (min-width: 1100px) {
  body {
    --ContainerWidth: 94vw;
  }
}

@media screen and (min-width: 1440px) {
  body {
    --ContainerWidth: 1332px;
  }
}

a:focus, button:focus {
  outline: 2px solid #f7c257;
}

@font-face {
  font-family: Hope Sans;
  font-weight: 400;
  src: url("font.62d218cf.woff2") format("woff2"), url("font.35f72ee7.woff") format("woff");
}

@font-face {
  font-family: Hope Sans;
  font-weight: 400;
  font-style: italic;
  src: url("font.5b471868.woff2") format("woff2"), url("font.467bec44.woff") format("woff");
}

@font-face {
  font-family: Hope Sans;
  font-weight: 600;
  src: url("font.95eb4334.woff2") format("woff2"), url("font.ab25c2ca.woff") format("woff");
}

@font-face {
  font-family: Hope Sans;
  font-weight: 700;
  src: url("font.a92a2497.woff2") format("woff2"), url("font.2e3f40fc.woff") format("woff");
}

body {
  font-family: Hope Sans, Europa, Helvetica Neue, Helvetica, sans-serif;
  font-size: 17px;
  font-weight: 300;
}

.o-hide, .u-vis-hidden {
  visibility: hidden;
}

.u-vis-visible {
  visibility: visible;
}

.c-container-units-demo__span, .c-code, .o-article-section, .u-width-100pc, .o-absfill, .o-aspect-ratio > *, .o-aspect-ratio:before {
  width: 100%;
}

.o-dictate {
  width: 1px;
}

.u-trans--50pc, .o-abscentre, .c-icon-link__icon:before, .c-poster__network-icon:before {
  transform: translate(-50%, -50%);
}

.u-transx--50pc, .o-abscentre-x {
  transform: translateX(-50%);
}

.u-transy--50pc, .o-abscentre-y {
  transform: translateY(-50%);
}

.u-pt-3rem {
  padding-top: 3rem;
}

.u-pv-3rem {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.u-gc-1 {
  grid-column: 1;
}

.u-gc-2 {
  grid-column: 2;
}

.u-gc-3 {
  grid-column: 3;
}

.u-gc-4 {
  grid-column: 4;
}

.u-gr-1 {
  grid-row: 1;
}

.u-gr-2 {
  grid-row: 2;
}

.u-gr-3 {
  grid-row: 3;
}

.u-gr-4 {
  grid-row: 4;
}

.u-bgcolor-white {
  background-color: #fff;
}

.u-bgcolor-paste {
  background-color: #fafcfc;
}

.u-bgcolor-gunge {
  background-color: #50e3c2;
}

.u-bgcolor-mango {
  background-color: #f7c257;
}

.u-bgcolor-charcoal-base-lighter {
  background-color: #262c39;
}

.u-bgcolor-charcoal-base {
  background-color: #212733;
}

.c-progress, .c-menu, .c-external-link, :not(pre) > code[class*="language-"], .c-code, .c-back, .s-article p a, .c-article figcaption p a, .u-bgcolor-charcoal-base-darker {
  background-color: #181d26;
}

.c-header--container-units, .u-bgcolor-purple-base {
  background-color: #602087;
}

.s-article, .c-article figcaption {
  line-height: 1.52381;
}

.s-article ul, .c-article figcaption ul, .s-article ol, .c-article figcaption ol {
  padding-left: 1em;
}

.s-article ul li, .c-article figcaption ul li {
  list-style-type: disc;
}

.s-article a, .c-article figcaption a {
  color: var(--color-mango);
}

.s-article p, .c-article figcaption p, .s-article li, .c-article figcaption li {
  font-family: Inter, sans-serif;
}

.s-article p a, .c-article figcaption p a {
  white-space: normal;
  border-radius: .3em;
  padding: 0 .3em;
  line-height: 1;
}

.s-article img, .c-article figcaption img {
  max-width: 100%;
}

.s-article * + *, .c-article figcaption * + * {
  margin-top: calc(3 * var(--Baseline));
}

.s-article * + .gist-meta, .c-article figcaption * + .gist-meta {
  margin-top: 0;
}

.s-article :not(h1):not(h2):not(h3) + h2, .c-article figcaption :not(h1):not(h2):not(h3) + h2, .s-article :not(h1):not(h2):not(h3) + h3, .c-article figcaption :not(h1):not(h2):not(h3) + h3 {
  margin-top: calc(12 * var(--Baseline));
}

.s-article code, .c-article figcaption code {
  white-space: normal;
  color: #50e3c2;
  background-color: #181d26;
  border-radius: .3em;
  padding: .2em .3em;
}

.o-aspect-ratio--16\:9:before {
  padding-top: 56.25%;
}

.o-container {
  width: var(--ContainerWidth);
}

.o-article-section > * {
  grid-column: 2;
}

.o-article-section__aside {
  grid-column: 1;
}

@media screen and (max-width: 1099px) {
  .o-article-section > * + * {
    margin-top: calc(12 * var(--Baseline));
  }
}

@media screen and (min-width: 1100px) {
  .o-article-section {
    grid-template-columns: calc(3 * (var(--ColumnUnit)  + var(--GutterUnit))  - var(--GutterUnit)) calc(7 * (var(--ColumnUnit)  + var(--GutterUnit))  - var(--GutterUnit)) calc(3 * (var(--ColumnUnit)  + var(--GutterUnit))  - var(--GutterUnit));
    grid-column-gap: calc(2 * (var(--GutterUnit)  + var(--ColumnUnit))  - var(--ColumnUnit));
    display: grid;
  }
}

.o-oversized, .c-code {
  margin-left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 1099px) {
  .o-oversized, .c-code {
    width: 100%;
  }
}

@media screen and (min-width: 1100px) {
  .o-oversized, .c-code {
    width: calc(9 * (var(--GutterUnit)  + var(--ColumnUnit))  - var(--ColumnUnit));
  }
}

.o-oversized > *, .c-code > * {
  max-width: 100%;
}

.o-ellipsis {
  text-overflow: ellipsis;
}

.o-type-h0 {
  font-size: clamp(28px, 4vw, 50px);
  line-height: 1.18;
}

.o-type-h1, .s-article h1, .c-article figcaption h1 {
  font-size: clamp(22px, 4vw, 34px);
}

.o-type-h2, .s-article h2, .c-article figcaption h2 {
  font-size: clamp(19px, 4vw, 27px);
}

.o-type-h3, .c-header__tags > *, .s-article h3, .c-article figcaption h3 {
  font-size: clamp(18px, 4vw, 24px);
}

.o-type-body, .s-article, .c-article figcaption {
  font-size: clamp(16px, 4vw, 18px);
}

.o-type-16px {
  font-size: 16px;
}

.c-article {
  padding-bottom: 7.5rem;
}

.c-article > * {
  padding-top: 7.5rem;
}

.c-logos {
  cursor: default;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.c-logo {
  width: 100%;
  max-width: 7.5rem;
  min-width: 4rem;
  color: #666;
  text-align: center;
  background: #d3d3d3;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  display: flex;
}

.c-logo__image {
  aspect-ratio: 1 / 1;
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 1px #0000001a;
}

.c-logo__image > * {
  width: 82%;
}

.c-logo__description {
  color: #000;
  padding: .5rem 0;
}

.c-stats {
  cursor: default;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  display: grid;
}

.c-stats > * {
  aspect-ratio: 4 / 1;
  border: 2px solid var(--color-gunge);
  border-radius: 6px;
  margin: 0;
  padding: 1rem;
}

.c-stats > * > * + * {
  margin-top: .5rem;
}

.c-stats dt {
  color: #a9a9a9;
}

.c-bunting > :nth-child(2n) {
  transform: translateY(.7rem);
}

.c-bunting > :nth-child(2n+1) {
  transform: rotate(2deg);
}

.c-bunting > :nth-child(5n) {
  transform: rotate(-2deg);
}

.u-border-radius-m {
  border-radius: 1rem;
}

.caption {
  cursor: default;
  border-radius: 6px;
  align-items: center;
  gap: .5rem;
  padding: .5rem .75rem;
  font-size: .9rem;
  display: flex;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

.caption.yellow {
  color: #2e2f32;
  background: #fbc541;
}

.caption.purple {
  color: #fff;
  background: #7f43e5;
}

.caption.green {
  color: #fff;
  background: #10921e;
}

.caption__icon {
  width: 1rem;
}

.c-article figure > * + * {
  margin-top: 2rem;
}

.c-article figure img {
  max-width: 100%;
}

.fill-white {
  filter: invert(99%) sepia(97%) saturate(290%) hue-rotate(172deg) brightness(119%) contrast();
}

.inline-logo {
  height: 2rem;
  background: #fff;
  border-radius: 3px;
  padding: .5rem;
}

table td {
  padding: 0 1rem;
}

@media (min-width: 600px) {
  .o-panels {
    align-items: start;
    gap: calc(var(--GutterUnit) * 2);
    display: flex;
  }

  .o-panels__main {
    width: calc(var(--ColumnAndGutterUnit) * 12 - var(--GutterUnit));
  }

  .o-panels__aside {
    width: 100%;
  }
}

.o-panels img {
  max-width: 100%;
}

.c-field {
  color: #333;
  background-color: #f5f5f5;
  border-top: none;
  border-bottom: 1px solid #d1d1d1;
  border-left: 1px solid #dde1e3;
  border-radius: 0 4px 4px;
  padding: 1rem;
}

.c-field__title {
  align-items: center;
  margin-left: .4rem;
  display: inline-flex;
}

.c-field__title span {
  margin-right: .4rem;
}

.c-field__description {
  padding: 1rem 0 0;
  font-size: 1rem;
}

.c-field pre {
  background: #fff;
  border-radius: 4px;
  padding: 2rem;
  box-shadow: 0 0 1px #00000052, 0 0 2px #00000014, 0 1px 3px #00000014;
}

.c-field pre code {
  font: inherit;
  color: #111;
  white-space: pre-wrap;
  background: none;
  line-height: 1.35;
}

.c-base-table {
  color: #4d4d4d;
  background: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin-left: 6px;
  margin-right: auto;
  padding: .4rem;
  display: inline-flex;
  box-shadow: 0 0 2px #00000014, 0 -1px 3px #00000029;
}

summary {
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  color: #333;
  background-color: #f5f5f5;
  border-top: none;
  border-left: 1px solid #dde1e3;
  border-radius: 4px;
  padding: 1rem 1.2rem 1rem 1rem;
}

details[open] summary {
  border-radius: 4px 4px 0 0;
}

summary::marker {
  color: currentColor;
  opacity: .6;
  padding-right: 3rem;
  display: inline-block;
}

details summary > * {
  display: inline-block;
}

:target {
  outline-offset: 1rem;
  outline: 2px solid #0000;
  animation: .2s linear highlightTarget;
}

@keyframes highlightTarget {
  50% {
    outline-color: var(--color-mango);
  }
}

.c-back {
  --ReturnHeight: calc(11 * var(--Baseline));
  height: var(--ReturnHeight);
}

.c-back__link {
  line-height: var(--ReturnHeight);
}

.c-code, :not(pre) > code[class*="language-"] {
  font-family: Source Code Pro, monospace;
  font-size: 1rem;
}

.c-code {
  margin-top: calc(6 * var(--Baseline));
  padding: calc(6 * var(--Baseline));
  color: #50e3c2;
}

.c-code + * {
  margin-top: calc(6 * var(--Baseline));
}

code[class*="language-"], pre[class*="language-"] {
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
}

pre[class*="language-"] {
  overflow: auto;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .2em .3em;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: #708090;
}

.namespace {
  opacity: .7;
}

.token.regex, .token.important, .token.variable {
  color: #e90;
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.c-content-feed {
  grid-row-gap: calc(12 * var(--Baseline));
  grid-column-gap: calc(6 * var(--Baseline));
  grid-area: 1 / 2;
  justify-content: center;
  align-items: end;
  display: grid;
}

@media (min-width: 480px) {
  .c-content-feed {
    grid-template-columns: repeat(auto-fill, minmax(320px, 420px));
  }
}

.c-home-feed {
  --document-gap: calc(2 * var(--Baseline));
  padding: var(--document-gap);
}

@media (min-width: 920px) and (min-aspect-ratio: 4 / 3) {
  .c-home-feed {
    grid-template-columns: auto minmax(320px, 1fr) auto calc(100vh - var(--document-gap) * 2);
    grid-column-gap: var(--document-gap);
    display: grid;
  }
}

.c-container-units-demo__column {
  width: var(--CUDemoColumnUnit);
  background-color: #f35f4a1a;
}

.c-container-units-demo__span {
  height: 10%;
  animation: var(--CUDemoDuration) updown infinite;
  will-change: transform;
  border-radius: 3px;
  justify-content: space-between;
}

.c-container-units-demo__span:before, .c-container-units-demo__span:after {
  width: calc(var(--CUDemoGutterUnit) / 2);
  height: 100%;
  border-radius: inherit;
  content: "";
  background-color: #40ffd2;
}

@keyframes updown {
  0%, 100% {
    transform: translateY(0);
  }

  0%, 50%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: .1;
  }

  25% {
    transform: translateY(-450%);
  }

  75% {
    transform: translateY(450%);
  }
}

.c-expo {
  grid-gap: calc(4 * var(--Baseline));
}

.c-expo a:after {
  z-index: 1;
  content: "";
  inset: 0;
}

.c-expo__meta {
  color: #bac2c0;
  font-size: 15px;
  line-height: 24px;
}

.c-expo__title {
  font-size: 1.15rem;
  font-weight: bold;
  line-height: 1.65;
}

.c-expo__thumbnail {
  aspect-ratio: 4 / 3;
}

.c-expo__thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0% 25% 15%;
}

.c-expo__thumbnail-face {
  width: 15%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  border-radius: 100%;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  box-shadow: 0 1px 3px #0006, 0 2px 5px #0003;
}

.c-external-link {
  --border-radius: .3em;
  border-radius: var(--border-radius);
  white-space: normal;
  align-items: center;
  line-height: 1;
  display: inline-flex;
}

.c-external-link__text {
  padding: 0 .7em;
}

.c-external-link__logo-base {
  padding: 0em var(--border-radius);
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.c-external-link__logo-image {
  height: 3rem;
  display: block;
}

.c-external-link--smashing .c-external-link__logo-base {
  background-color: #d33a2c;
}

.c-header__title {
  text-shadow: 0 2px #602087;
}

.c-header__title > * + * {
  margin-top: calc(2 * var(--Baseline));
}

.c-header__tags > * {
  margin-right: calc(2 * var(--Baseline));
}

.c-header--container-units {
  height: var(--ArticleHeaderHeight);
}

.c-header--container-units .c-header__title {
  max-width: 600px;
}

@media screen and (max-width: 1099px) {
  .c-header--container-units {
    grid-template-columns: 1fr calc((var(--CUDemoWidth)  - var(--ArticleWidth)) / 2) var(--ArticleWidth) calc((var(--CUDemoWidth)  - var(--ArticleWidth)) / 2) 1fr;
    grid-template-rows: repeat(3, 1fr);
  }

  .c-header--container-units .c-header__title {
    grid-area: 2 / 3;
  }

  .c-header--container-units .c-header__demo {
    grid-area: 2 / 2 / span 2 / span 3;
  }
}

@media screen and (min-width: 1100px) {
  .c-header--container-units {
    grid-template-columns: 2fr calc((var(--CUDemoWidth)  - var(--ArticleWidth)) / 2) var(--ArticleWidth) calc((var(--CUDemoWidth)  - var(--ArticleWidth)) / 2) 2fr;
    grid-template-rows: repeat(5, 1fr);
  }

  .c-header--container-units .c-header__title {
    grid-area: 3 / 3;
  }

  .c-header--container-units .c-header__demo {
    grid-area: 2 / 2 / span 4 / span 3;
  }
}

.c-header--anonymised-hiring .background {
  width: 100%;
  mix-blend-mode: soft-light;
  opacity: .4;
  position: absolute;
  top: 0;
  left: 0;
}

.c-poster {
  --color-primary: var(--color-mango);
  width: 100%;
  max-width: calc(100vh - var(--document-gap) * 2);
  height: calc(100vh - var(--document-gap) * 2);
  text-shadow: 0 1px #00000080;
  background-color: #181d26;
  grid-column: 4;
  grid-template-rows: 4fr 2fr 1fr;
  grid-template-columns: repeat(4, 1fr);
  margin-left: auto;
  margin-right: auto;
  display: grid;
  overflow: hidden;
}

@media (min-width: 920px) and (min-aspect-ratio: 4 / 3) {
  .c-poster {
    top: var(--document-gap);
    position: sticky;
  }
}

.c-poster__headings {
  grid-area: 1 / 1 / 2 / -1;
  padding: 5vmin;
}

.c-poster__headings > * + * {
  margin-top: 1rem;
}

@media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3 / 4) {
  .c-poster__headings {
    grid-column: 1 / 3;
    padding: 5vmin 5vmin 16vmin;
  }
}

.c-poster__headings, .c-poster__skills {
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.c-poster__name, .c-poster__title, .c-poster__network-icon, .c-poster__skill {
  z-index: 10;
}

.c-poster__name {
  letter-spacing: -.002em;
  font-size: 1.5rem;
}

@media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3 / 4) {
  .c-poster__name {
    font-size: 2.8vmin;
  }
}

.c-poster__title {
  letter-spacing: -.012em;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.16;
}

@media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3 / 4) {
  .c-poster__title {
    font-size: 5.8vmin;
  }
}

.c-poster__skills {
  grid-area: 2 / 1 / 3 / -1;
  padding: 8vmin 5vmin;
  line-height: 1.3;
}

.c-poster__skills > * + * {
  margin-top: .7em;
}

@media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3 / 4) {
  .c-poster__skills {
    grid-area: 2 / 1 / 4 / 4;
    padding: 5vmin;
  }
}

.c-poster__skill {
  z-index: 10;
}

.c-poster__skill a {
  align-items: center;
  display: inline-flex;
}

.c-poster__networks {
  --PosterSize: 30px;
  z-index: 10;
  grid-area: 3 / 1 / 4 / -1;
  align-items: center;
  padding: 5vmin;
}

.c-poster__networks > :not(:first-of-type) {
  margin-left: calc(var(--PosterSize) * .75);
}

@media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3 / 4) {
  .c-poster__networks {
    grid-area: 1 / 1 / 2 / 3;
    align-items: flex-end;
  }
}

.c-poster__network-link {
  gap: .5rem;
}

.c-poster__network-icon {
  width: 2rem;
  aspect-ratio: 1 / 1;
}

.c-poster__network-icon:before {
  width: 140%;
  aspect-ratio: 1 / 1;
  content: "";
  border-radius: 50%;
  transition: all .18s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-poster__network-icon svg {
  color: currentColor;
  stroke: currentColor;
}

.c-poster__network-link:hover .c-poster__network-icon:before, .c-poster__network-link:focus .c-poster__network-icon:before {
  background-color: #ffffff1a;
}

.c-poster__portrait {
  z-index: 1;
  opacity: .3;
  grid-area: 1 / 1 / -1 / -1;
  transition: opacity .4s;
}

@media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3 / 4) {
  .c-poster__portrait {
    opacity: 1;
    grid-area: 1 / 2 / -1 / 5;
  }
}

.c-poster__image {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: 0% 50%;
}

@media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3 / 4) {
  .c-poster__image {
    object-position: 5% 50%;
  }
}

.c-poster__shade {
  z-index: 2;
  opacity: .4;
  mix-blend-mode: multiply;
  background: #181d26;
  grid-area: 3 / 1 / 4 / 5;
}

@media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3 / 4) {
  .c-poster__shade {
    background: var(--color-primary);
    grid-row: 2 / 4;
  }
}

.c-poster__slant {
  z-index: 5;
  mix-blend-mode: hard-light;
  grid-area: 2 / 1 / 4 / 3;
  display: none;
}

.c-poster__slant svg {
  mix-blend-mode: hard-light;
}

@media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3 / 4) {
  .c-poster__slant {
    display: block;
  }
}

.c-poster__svg {
  width: 100%;
  height: auto;
  fill: var(--color-primary);
  display: block;
}

.c-icon-link {
  gap: .5rem;
}

.c-icon-link__icon {
  width: 24px;
  aspect-ratio: 1 / 1;
}

.c-icon-link__icon:before {
  width: 180%;
  aspect-ratio: 1 / 1;
  content: "";
  border-radius: 50%;
  transition: all .18s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-icon-link__icon > * {
  width: 100%;
  aspect-ratio: 1 / 1;
  color: currentColor;
  stroke: currentColor;
  object-fit: contain;
}

.c-icon-link:hover .c-icon-link__icon:before, .c-icon-link:focus .c-icon-link__icon:before {
  background-color: #ffffff1a;
}

.c-menu {
  --ProgressHeight: calc(11 * var(--Baseline));
  margin-top: 7.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media screen and (min-width: 1100px) {
  .c-menu {
    top: calc(var(--ProgressHeight)  + calc(2 * var(--Baseline)));
    position: sticky;
  }
}

.c-menu li li {
  opacity: .8;
  font-size: .9rem;
}

.c-menu li:has(ol) > ol {
  margin-left: 1rem;
}

.c-menu a {
  padding: calc(1 * var(--Baseline)) calc(3 * var(--Baseline));
}

.c-progress {
  z-index: 10;
  top: 0;
}

.c-progress__screen {
  height: calc(11 * var(--Baseline));
  --TotalSections: 6;
  --CurrentSection: 1;
}

@media (max-width: 6490px) {
  .c-progress__screen {
    opacity: 0;
    pointer-events: none;
  }
}

@media screen and (max-width: 1099px) {
  .c-progress__screen {
    margin-left: calc(5 * (var(--ColumnUnit)  + var(--GutterUnit))  - var(--GutterUnit));
  }
}

@media screen and (min-width: 1100px) {
  .c-progress__screen {
    width: calc(7 * (var(--ColumnUnit)  + var(--GutterUnit))  - var(--GutterUnit));
  }
}

.c-progress__screen:before, .c-progress__screen:after {
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.c-progress__screen:before {
  width: 100%;
  background-color: #50e3c24d;
}

.c-progress__screen:after {
  width: 100%;
  transform: scaleX(calc(1 / var(--TotalSections) * var(--CurrentSection)));
  transform-origin: 0;
  background-color: #50e3c2;
  transition: all .2s;
}

/*# sourceMappingURL=anonymised-hiring-platform.2fbeb525.css.map */
