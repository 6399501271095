/**
 * .c-header
 */

$component: 'header';
#{'.' + $global-components-namespace + $component} {
    @extend %u-ov-hidden;
    @extend %u-pos-relative;
    &__title {
        @extend %u-pos-relative;
        @extend %u-flex;
        @extend %u-fd-column;
        text-shadow: 0 2px 0 $color-purple-base;
        >*+* {
            margin-top: baselines(2);
        }
    }
    &__tags {
        @extend %u-color-gunge;
        >* {
            @extend .o-type-h3;
            margin-right: baselines(2);
        }
    }
}

$component: 'header';
$modifier: 'container-units';
#{'.' + $global-components-namespace + $component} {
    &--#{$modifier} {
        @extend %u-grid;
        @extend %u-bgcolor-purple-base;
        height: var(--ArticleHeaderHeight);
    }
    &--#{$modifier} &__title {
        @extend %u-as-center;
        @extend %u-js-center;
        max-width: 600px;
    }
    @include breakpoint(uptoarticle3col) {
        &--#{$modifier} {
            grid-template-columns: 1fr calc((var(--CUDemoWidth) - var(--ArticleWidth)) / 2) var(--ArticleWidth) calc((var(--CUDemoWidth) - var(--ArticleWidth)) / 2) 1fr;
            grid-template-rows: repeat(3, 1fr);
        }
        &--#{$modifier} &__title {
            grid-column: 3;
            grid-row: 2;
        }
        &--#{$modifier} &__demo {
            grid-column: 2 / span 3;
            grid-row: 2 / span 2;
        }
    }
    @include breakpoint(fromarticle3col) {
        &--#{$modifier} {
            grid-template-columns: 2fr calc((var(--CUDemoWidth) - var(--ArticleWidth)) / 2) var(--ArticleWidth) calc((var(--CUDemoWidth) - var(--ArticleWidth)) / 2) 2fr;
            grid-template-rows: repeat(5, 1fr);
        }
        &--#{$modifier} &__title {
            grid-column: 3;
            grid-row: 3;
        }
        &--#{$modifier} &__demo {
            grid-column: 2 / span 3;
            grid-row: 2 / span 4;
        }
    }
}

.c-header--anonymised-hiring {
    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // object-fit: cover;
        mix-blend-mode: soft-light;
        opacity: .4;
    }
}