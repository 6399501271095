/**
 * .o-container
 */

$object: 'container';

#{'.' + $global-objects-namespace + $object} {
  @extend .u-mh-auto;
  width: var(--ContainerWidth);
}
