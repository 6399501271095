/**
 * .c-progress
 */

$component: 'progress';

#{'.' + $global-components-namespace + $component} {
  @extend %u-pos-sticky;
  @extend %u-bgcolor-charcoal-base-darker;
  top: 0;
  z-index: 10;
  
  &__screen {
    @extend %u-pos-relative;
    @extend %u-mv-auto; 
    @extend %u-mh-auto; 
    @extend %u-flex; 
    @extend %u-jc-space-between; 
    @extend %u-ai-center;
    height: baselines(11);

    @media (max-width: 6490px) {
      opacity: 0;
      pointer-events: none;
    }

    @include breakpoint(uptoarticle3col) {
      margin-left: columnspans(5);
    }

    @include breakpoint(fromarticle3col) {
      width: columnspans(7);
    }

    &:before,
    &:after {
      @extend %u-content;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
    }

    --TotalSections: 6;
    --CurrentSection: 1;

    &:before {
      width: 100%;
      background-color: rgba($color-gunge, .3);
    }

    &:after {
      width: 100%;
      background-color: $color-gunge;
      transform: scaleX(calc(1 / var(--TotalSections) * var(--CurrentSection)));
      transform-origin: left;
      transition: all .2s;
    }
  }
}