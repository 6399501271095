$component: 'container-units-demo';

#{'.' + $global-components-namespace + $component} {
  @extend %u-flex;
  @extend %u-jc-space-between;
  // opacity: .5;

  &__column {
    @extend %u-flex;
    @extend %u-ai-center;
    @extend %u-jc-center;
    width: var(--CUDemoColumnUnit);
    background-color: rgba(#F35F4A, .1);
  }

  &__span {
    @extend %u-flex;
    @extend %u-width-100pc;
    height: 10%;
    justify-content: space-between;
    border-radius: 3px;
    animation: var(--CUDemoDuration) updown infinite;
    will-change: transform;

    &:before,
    &:after {
      width: calc(var(--CUDemoGutterUnit) / 2);
      height: 100%;
      background-color: $colour-aqua;
      border-radius: inherit;
      content: '';
    }
  }
}

@keyframes updown {
  0%,
  100% {
    transform: translateY(0);
  }

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: .1;
  }

  25% {
    transform: translateY(-450%);
  }

  75% {
    transform: translateY(450%);
  }
}