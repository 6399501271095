// Global color variables
$color-white: #FFFFFF;
$color-paste: #FAFCFC;
$color-mist: #BAC2C0;

$color-gunge: #50E3C2;

$color-mango: #F7C257;

$color-charcoal-base-lighter: #262C39;
$color-charcoal-base: #212733;
$color-charcoal-base-darker: #181D26;

// Container Units
$color-purple-base: #602087;
$colour-aqua: #40FFD2;


// Auto Colors
$auto-colors: (
  'white': $color-white,
  'paste': $color-paste,
  
  'gunge': $color-gunge,
  'mango': $color-mango,

  'charcoal-base-lighter': $color-charcoal-base-lighter,
  'charcoal-base': $color-charcoal-base,
  'charcoal-base-darker': $color-charcoal-base-darker,

  'purple-base': $color-purple-base,
);


// Make color variables
$vars-colors: (
  'white': $color-white,
  'gunge': $color-gunge,
  'mango': $color-mango,
);

body {
  @each $alias, $color in $vars-colors {
    --color-#{$alias}: #{$color};
  };
}