    /**
 * .s-article
 */
    
    $scoped: 'article';
    #{'.' + $global-scoped-namespace + $scoped} {
        @extend .o-type-body;
        line-height: calc(32/21);
        h1,
        h2,
        h3 {}
        h1 {
            @extend .o-type-h1;
        }
        h2 {
            @extend .o-type-h2;
        }
        h3 {
            @extend .o-type-h3;
        }
        ul,
        ol {
            padding-left: 1em;
        }
        ul li {
            list-style-type: disc;
        }
        ol li {}
        li {
            // list-style-position: inside;
        }
        a {
            color: var(--color-mango);
        }
        p,
        li {
            font-family: 'Inter', sans-serif;
        }
        p a {
            @extend %u-bgcolor-charcoal-base-darker;
            @extend %u-color-mango;
            padding: .0em .3em;
            border-radius: .3em;
            white-space: normal;
            line-height: 1;
        }
        img {
            max-width: 100%;
        }
        *+ {
            * {
                margin-top: baselines(3);
            }
            .gist-meta {
                margin-top: 0;
            }
        }
        *:not(h1):not(h2):not(h3)+ {
            h2,
            h3 {
                margin-top: baselines(12);
            }
        }
        code {
            white-space: normal;
            border-radius: 0.3em;
            padding: 0.2em 0.3em;
            // font-family: Source Code Pro, monospace;
            // font-size: 1rem;
            background-color: #181d26;
            color: #50e3c2;
        }
    }