/**
 * .c-content-feed
 */

$component: 'content-feed';

#{'.' + $global-components-namespace + $component} {
  @extend %u-grid;
  display: grid;
  
  grid-row-gap: baselines(12);
  grid-column-gap: baselines(6);
  justify-content: center;
  align-items: end;

  grid-row: 1;
  grid-column: 2;

  @media (min-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(320px, 420px));
  }
}



.c-home-feed {
  --document-gap: #{baselines(2)};
  padding: var(--document-gap);

  @media #{$move-poster-right} {
    display: grid;
    grid-template-columns: auto minmax(320px, 1fr) auto calc(100vh - (var(--document-gap) * 2));
    grid-column-gap: var(--document-gap);
  }
}