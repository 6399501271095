& {
    // Grid proportions config
    --CUDemoGridWidth: 1062;
    --CUDemoGridColumnWidth: 68;
    --CUDemoGridColumns: 12;
  
    // Grid logic
    --CUDemoGutters: calc(var(--CUDemoGridColumns) - 1);
  
    // Grid proportion logic
    --CUDemoColumnProportion: calc(var(--CUDemoGridColumnWidth) / var(--CUDemoGridWidth));
    --CUDemoGutterProportion: calc((1 - (var(--CUDemoGridColumns) * var(--CUDemoColumnProportion))) / var(--CUDemoGutters));
  
    // Container Units
    --CUDemoColumnUnit: calc(var(--CUDemoColumnProportion) * var(--CUDemoWidth));
    --CUDemoGutterUnit: calc(var(--CUDemoGutterProportion) * var(--CUDemoWidth));
    --CUDemoGridColumnspanUnit: calc(var(--CUDemoGridColumnspans) * (var(--CUDemoColumnUnit) + var(--CUDemoGutterUnit)) - var(--CUDemoGutterUnit));
  
    // Demo Width
    --CUDemoWidth: 92vw;
  
    @include breakpoint(fromarticle3col) {
      --CUDemoWidth: 80vw;
    }
  
    @include breakpoint(frommax) {
      --CUDemoWidth: 1150px;
    }

    /*
    --ScrollTop: 0;
    --CUDemoDuration: calc(1.5s + var(--CUDemoDurationExtra));
    --CUDemoDurationExtra: calc(var(--ScrollTop) * .003s);
    */
    --CUDemoDuration: 1.5s;
  }