& {
    // Grid proportions config
    --GridWidth: 1332;
    --GridColumnWidth: 72;
    --GridColumns: 15;
    // Grid logic
    --GridGutters: calc(var(--GridColumns) - 1);
    // Grid proportion logic
    --ColumnProportion: calc(var(--GridColumnWidth) / var(--GridWidth));
    --GutterProportion: calc((1 - (var(--GridColumns) * var(--ColumnProportion))) / var(--GridGutters));
    // Container Units
    --ColumnUnit: calc(var(--ColumnProportion) * var(--ContainerWidth));
    --GutterUnit: calc(var(--GutterProportion) * var(--ContainerWidth));
    --ColumnAndGutterUnit: calc(var(--ColumnUnit) + var(--GutterUnit));
    // Container Width
    --ContainerWidth: 86vw;
    @include breakpoint(fromarticle3col) {
        --ContainerWidth: 94vw;
    }
    @include breakpoint(frommax) {
        --ContainerWidth: 1332px;
    }
}