/**
 * .c-code
 */

$component: 'code';

#{'.' + $global-components-namespace + $component},
:not(pre) > code[class*="language-"] {
  font-family: 'Source Code Pro', monospace;
  font-size: 1rem;
}

#{'.' + $global-components-namespace + $component} {
  @extend .o-oversized;
  @extend %u-width-100pc;
  @extend %u-bgcolor-charcoal-base-darker;
  margin-top: baselines(6);
  padding: baselines(6);
  color: $color-gunge;
  
  & + * {
    margin-top: baselines(6);
  }

  // @include breakpoint(fromarticle3col) {
  //   width: gutterspans(8);
  // }
}

/* PrismJS 1.15.0
https://prismjs.com/download.html#themes=prism&languages=markup+css */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

 code[class*="language-"],
 pre[class*="language-"] {
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  hyphens: none;
 }
 
 pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
 code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
 }
 
 pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
 code[class*="language-"]::selection, code[class*="language-"] ::selection {
 }
 
 /* Code blocks */
 pre[class*="language-"] {
   overflow: auto;
 }
 
 :not(pre) > code[class*="language-"],
 pre[class*="language-"] {
 }
 
 /* Inline code */
 :not(pre) > code[class*="language-"] {
  @extend %u-bgcolor-charcoal-base-darker;
  @extend %u-color-gunge;
  padding: .2em .3em;
  border-radius: .3em;
  white-space: normal;
 }
 
 .token.comment,
 .token.prolog,
 .token.doctype,
 .token.cdata {
   color: slategray;
 }
 
 .token.punctuation {
  //  color: #999;
 }
 
 .namespace {
   opacity: .7;
 }
 
 .token.property,
 .token.tag,
 .token.boolean,
 .token.number,
 .token.constant,
 .token.symbol,
 .token.deleted {
  //  color: #905;
 }
 
 .token.selector,
 .token.attr-name,
 .token.string,
 .token.char,
 .token.builtin,
 .token.inserted {
  //  color: $color-mango;
  //  font-weight: bold;
 }
 
 .token.operator,
 .token.entity,
 .token.url,
 .language-css .token.string,
 .style .token.string {
  //  color: #9a6e3a;
  //  background: hsla(0, 0%, 100%, .5);
 }
 
 .token.atrule,
 .token.attr-value,
 .token.keyword {
  //  color: #07a;
 }
 
 .token.function,
 .token.class-name,
 .token.punctuation,
 .token.property {
   @extend %u-color-paste;
  //  color: #DD4A68;
 }
 
 .token.regex,
 .token.important,
 .token.variable {
   color: #e90;
 }
 
 .token.important,
 .token.bold {
   font-weight: bold;
 }
 .token.italic {
   font-style: italic;
 }
 
 .token.entity {
   cursor: help;
 }
 