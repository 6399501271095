/**
 * .c-article
 */

$component: 'article';
#{'.' + $global-components-namespace + $component} {
    padding-bottom: 7.5rem;
    >* {
        padding-top: 7.5rem;
        // Why padding? 
        // Because we're #hash-linking to each section, and we want some space at the top of the window
    }
}

.c-logos {
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    cursor: default;
}

.c-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 7.5rem;
    min-width: 4rem;
    // aspect-ratio: 1 / .9;
    background: lightgray;
    border-radius: 6px;
    // border: 2px solid grey;
    // box-shadow: 0 2px 6px rgba(0, 0, 0, 4);
    color: #666;
    text-align: center;
    font-size: 11px;
    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1 / 1;
        background-color: white;
        border-radius: 4px;
        box-shadow: 0 1px 0 rgba(0, 0, 0, .1);
        >* {
            width: 82%;
        }
    }
    &__description {
        padding: .5rem 0;
        color: black;
    }
}

.c-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    cursor: default;
    >* {
        aspect-ratio: 4 / 1;
        padding: 1rem;
        margin: 0;
        // background: rgba(white, .4);
        border: 2px solid var(--color-gunge);
        border-radius: 6px;
        >*+* {
            margin-top: .5rem;
        }
    }
    dt {
        color: darkgray;
    }
}

.c-bunting {
    >*:nth-child(even) {
        transform: translateY(.7rem);
    }
    >*:nth-child(odd) {
        transform: rotate(2deg);
    }
    >*:nth-child(5n) {
        transform: rotate(-2deg);
    }
    // >*:nth-child(4n) {
    //     transform: translateY(-.2rem);
    // }
}

.u-border-radius-m {
    border-radius: 1rem;
}

.caption {
    position: absolute;
    bottom: .5rem;
    right: .5rem;
    display: flex;
    align-items: center;
    border-radius: 6px;
    font-size: .9rem;
    padding: .5rem .75rem;
    gap: .5rem;
    cursor: default;
    &.yellow {
        color: #2e2f32;
        background: #fbc541;
    }
    &.purple {
        color: white;
        background: #7f43e5;
    }
    &.green {
        color: white;
        background: #10921e;
    }
    &__icon {
        width: 1rem;
    }
}

.c-article {
    figure {
        >*+* {
            margin-top: 2rem;
        }
        img {
            max-width: 100%;
        }
    }
    figcaption {
        @extend .s-article;
    }
}

.fill-white {
    filter: invert(99%) sepia(97%) saturate(290%) hue-rotate(172deg) brightness(119%) contrast(100%);
}

.inline-logo {
    height: 2rem;
    background: #fff;
    border-radius: 3px;
    padding: 0.5rem;
}

table {
    td {
        padding: 0 1rem;
    }
}

.u-alternating-flex {
    >*:nth-child(even) {
        // flex-direction: row-reverse;
    }
}

.o-panels {
    @media (min-width: 600px) {
        display: flex;
        align-items: start;
        gap: calc(var(--GutterUnit) * 2);
        &__main {
            width: calc((var(--ColumnAndGutterUnit) * 12) - var(--GutterUnit));
        }
        &__aside {
            width: 100%;
        }
    }
    img {
        max-width: 100%;
    }
}

.c-field {
    color: hsl(0,0%,20%);
    background-color: #f5f5f5;
    border-bottom: 1px solid hsl(0,0%,82%);
    border-top: none;
    border-left: 1px solid hsl(202,10%,88%);
    padding: 1rem;
    border-radius: 0 4px 4px 4px;

    &__title {
        display: inline-flex;
        align-items: center;
        margin-left: .4rem;

        span {
            margin-right: .4rem;
        }
    }

    &__description {
        padding: 1rem 0 0;
        font-size: 1rem;
    }

    pre {
        background: white;
        box-shadow: 0 0 1px rgba(0,0,0,0.32), 0 0 2px rgba(0,0,0,0.08), 0 1px 3px rgba(0,0,0,0.08);
        padding: 2rem;
        border-radius: 4px;

        code {
            background: transparent;
            font: inherit;
            color: #111;
            white-space: pre-wrap;
            line-height: 1.35;
        }
    }
}

.c-base-table {
    margin-right: auto;
    margin-left: 6px;
    display: inline-flex;
    padding: .4rem;
    background: white;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    color: rgb(77,77,77);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 2px, rgba(0, 0, 0, 0.16) 0px -1px 3px;
}

summary {
    // display: inline-flex;
    padding: 1rem 1.2rem 1rem 1rem;
    width: fit-content;
    cursor: pointer;

    color: hsl(0,0%,20%);
    background-color: #f5f5f5;
    // border-bottom: 1px solid hsl(0,0%,82%);
    border-top: none;
    border-left: 1px solid hsl(202,10%,88%);
    border-radius: 4px;
}

details[open] summary {
    border-radius: 4px 4px 0 0;
}

summary::marker {
    display: inline-block;
    padding-right: 3rem;
    color: currentColor;
    opacity: .6;
}

details summary > * {
    display: inline-block;
  }

:target {
    outline-offset: 1rem;
    animation: .2s highlightTarget linear;
    outline-width: 2px;
    outline-style: solid;
    outline-color: transparent;
}

@keyframes highlightTarget {
    // 0% {
    //     outline-color: transparent;
    // }

    50% {
        outline-color: var(--color-mango);
    }

    // 0% {
    //     outline-color: transparent;
    // }
    
}