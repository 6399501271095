html, 
body {
  background-color: $color-charcoal-base;
}

html {
  height: 100%;
}

body {
  min-height: 100%;

  &.t-article {
    @extend %u-flex;
    @extend %u-fw-wrap;
    @extend %u-fd-row-reverse;
  }

  @import 
  'vars-spacing',
  'vars-article',
  'vars-container-units-demo',
  'vars-container';
}

a,
button {
  &:focus {
    outline: $color-mango solid 2px;
  }
}