& {

  // Article Width
  --ArticleWidth: var(--ContainerWidth);

  @include breakpoint(fromarticle3col) {
    --ArticleWidth: #{columnspans(7)};
  }

  --ArticleHeaderHeight: 60vh;
}