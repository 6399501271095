.c-poster {
    // @extend %u-bgcolor-charcoal-base-darker;
    --color-primary: var(--color-mango);
    overflow: hidden;
    display: grid;
    width: 100%;
    max-width: calc(100vh - (var(--document-gap) * 2));
    height: calc(100vh - (var(--document-gap) * 2));
    margin-right: auto;
    margin-left: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 4fr 2fr 1fr;
    grid-column: 4;
    background-color: $color-charcoal-base-darker;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .5);
    @media #{$move-poster-right} {
        position: sticky;
        top: var(--document-gap);
    }
    &__headings {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        padding: 5vmin 5vmin;
        >*+* {
            margin-top: 1rem;
        }
        @media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3/4) {
            grid-column: 1 / 3;
            padding: 5vmin 5vmin 16vmin;
        }
    }
    &__headings,
    &__skills {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    &__name,
    &__title,
    &__network-icon,
    &__skill {
        z-index: 10;
    }
    &__name {
        font-size: 1.5rem;
        // color: var(--color-primary);
        letter-spacing: -0.002em;
        @media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3/4) {
            font-size: 2.8vmin;
        }
    }
    &__title {
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: -0.012em;
        line-height: calc(58/50);
        @media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3/4) {
            font-size: 5.8vmin;
        }
    }
    &__skills {
        grid-row: 2 / 3;
        grid-column: 1 / -1;
        padding: 8vmin 5vmin;
        line-height: 1.3;
        >*+* {
            margin-top: .7em;
        }
        @media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3/4) {
            grid-row: 2 / 4;
            grid-column: 1 / 4;
            padding: 5vmin 5vmin;
        }
    }
    &__skill {
        z-index: 10;
        a {
            display: inline-flex;
            align-items: center;
            // text-decoration: underline;
        }
    }
    &__networks {
        @extend %u-flex;
        --PosterSize: 30px;
        z-index: 10;
        padding: 5vmin 5vmin;
        grid-row: 3 / 4;
        grid-column: 1 / -1;
        align-items: center;
        >*:not(:first-of-type) {
            margin-left: calc(var(--PosterSize) * .75);
        }
        @media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3/4) {
            grid-row: 1 / 2;
            grid-column: 1 / 3;
            align-items: flex-end;
        }
    }
    &__network-link {
        @extend %u-flex;
        @extend %u-ai-center;
        @extend %u-pos-relative;
        gap: .5rem;
        &:hover {
            @extend %u-color-mango;
        }
    }
    &__network-icon {
        @extend %u-pos-relative;
        @extend %u-flex;
        @extend %u-ai-center;
        @extend %u-jc-center;
        width: 2rem;
        aspect-ratio: 1 / 1;
        &:before {
            @extend .o-abscentre;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 140%;
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            content: '';
            transition: all .18s;
        }
        svg {
            color: currentColor;
            stroke: currentColor;
        }
    }
    &__network-text {
        @extend %u-ws-nowrap;
    }
    &__network-link:hover &__network-icon:before,
    &__network-link:focus &__network-icon:before {
        background-color: rgba($color-white, .1);
    }
    &__portrait {
        z-index: 1;
        opacity: .3;
        grid-row: 1 / -1;
        grid-column: 1 / -1;
        transition: opacity .4s; // for the resizing, homie
        // When BOTH Axis are above 480px
        @media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3/4) {
            opacity: 1;
            grid-row: 1 / -1;
            grid-column: 2 / 5;
        }
    }
    &__image {
        width: 100%;
        // min-height: calc(100% + 1px);
        min-height: 100%;
        object-fit: cover;
        object-position: 0% 50%;
        @media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3/4) {
            object-position: 5% 50%;
        }
    }
    &__shade {
        z-index: 2;
        opacity: 0.4;
        grid-row: 3 / 4;
        grid-column: 1 / 5;
        background: $color-charcoal-base-darker;
        mix-blend-mode: multiply;
        @media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3/4) {
            grid-row: 2 / 4;
            background: var(--color-primary);
        }
    }
    &__slant {
        display: none;
        z-index: 5;
        grid-row: 2 / 4;
        grid-column: 1 / 3;
        mix-blend-mode: hard-light;
        svg {
            mix-blend-mode: hard-light;
        }
        @media (min-width: 480px) and (min-height: 480px) and (min-aspect-ratio: 3/4) {
            display: block;
        }
    }
    &__svg {
        display: block;
        width: 100%;
        height: auto;
        fill: var(--color-primary);
    }
}

.c-icon-link {
    @extend %u-flex;
    @extend %u-ai-center;
    @extend %u-pos-relative;
    gap: .5rem;
    &:hover {
        @extend %u-color-mango;
    }
    &__icon {
        @extend %u-pos-relative;
        @extend %u-flex;
        @extend %u-ai-center;
        @extend %u-jc-center;
        width: 24px;
        aspect-ratio: 1 / 1;
        &:before {
            @extend .o-abscentre;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 180%;
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            content: '';
            transition: all .18s;
        }
        >* {
            width: 100%;
            aspect-ratio: 1 / 1;
            color: currentColor;
            stroke: currentColor;
            object-fit: contain;
        }
    }
    &:hover &__icon:before,
    &:focus &__icon:before {
        background-color: rgba($color-white, .1);
    }
    &__text {
        @extend %u-ws-nowrap;
    }
}