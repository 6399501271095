/**
 * .o-article-section
 */

$object: 'article-section';
#{'.' + $global-objects-namespace + $object} {
    @extend %u-width-100pc;
    >* {
        grid-column: 2;
        // grid-row: 1;
    }
    &__aside {
        grid-column: 1;
        // align-self: end;
    }
    @include breakpoint(uptoarticle3col) {
        >*+* {
            margin-top: baselines(12);
        }
    }
    @include breakpoint(fromarticle3col) {
        display: grid;
        grid-template-columns: columnspans(3) columnspans(7) columnspans(3);
        grid-column-gap: gutterspans(2);
    }
}