/**
 * .c-external-link
 */

$component: 'external-link';

#{'.' + $global-components-namespace + $component} {
  --border-radius: .3em;
  display: inline-flex;
  align-items: center;
  @extend %u-bgcolor-charcoal-base-darker;
  @extend %u-color-mango;
  border-radius: var(--border-radius);
  white-space: normal;
  line-height: 1;
  
  &__text {
    padding: .0em .7em;
  }

  &__logo-base {
    padding: .0em var(--border-radius);
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  &__logo-image {
    display: block;
    height: 3rem;
  }

  &--smashing &__logo-base {
    background-color: #D33A2C;
  }
}