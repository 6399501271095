/**
 * .c-menu
 */

$component: 'menu';
#{'.' + $global-components-namespace + $component} {
    --ProgressHeight: #{baselines(11)};
    @extend %u-bgcolor-charcoal-base-darker;
    margin-top: 7.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include breakpoint(fromarticle3col) {
        position: sticky;
        top: calc(var(--ProgressHeight) + #{baselines(2)});
    }
    ol {}
    li {
        li {
            // margin-left: 1rem;
            font-size: .9rem;
            opacity: .8;
        }
    }
    li:has(ol)>ol {
        margin-left: 1rem;
    }
    a {
        @extend %u-block;
        padding: baselines(1) baselines(3);
    }
    code {}
}